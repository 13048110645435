/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material'
import { Box, Divider, IconButton, SwipeableDrawer, List, Link, ListItem, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useSelector } from 'react-redux'

import { currentPortal } from 'components/materialTheme/commonTheme'
import { switchDarkLightMode } from 'components/materialTheme/theme'
import type { StateType } from 'components/search/redux'
import { useCarouselConfigs, useNavbarContext, useOverviewPages } from 'hooks/data/misc'
import useUrls from 'hooks/useUrls'

import CollapsibleItem from './CollapsibleMenuItem'
import Item from './MenuItem'
import useNavbarStyles from './navbarStyles'

const drawerWidth = 316

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    menuCloseButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0.5)
        }
    },
    hide: {
        visible: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        '& a': {
            color: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.link.background
            }
        }
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.card
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: 72,
        backgroundColor: theme.palette.navbar.backgroundColor,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 62
        },
        '& a': {
            '&:hover': {
                backgroundColor: 'transparent !important'
            }
        }
    },
    bold: {
        fontWeight: 'bold'
    }
}))

const disableTheming = Boolean(document.querySelector('.doNotTheme'))

export default function SideMenu() {
    const classes = useStyles()
    const navbarClasses = useNavbarStyles()
    const urls = useUrls()
    const theme = useTheme()
    const isLoggedIn = useSelector((state: StateType) => state.isLoggedIn)
    const registrationOpen = useSelector((state: StateType) => state.registrationOpen)
    const userIsOnlyInTraineeGroup = useSelector((state: StateType) => state.userIsOnlyInTraineeGroup)
    const { data: carouselConfigs } = useCarouselConfigs()
    const { data: context } = useNavbarContext()
    const { data: overviewPages } = useOverviewPages()
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const overviewPagesContains = id => overviewPages?.some(entry => entry.id === id)

    return (
        <React.Fragment>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                data-testid="navbarSideMenu"
                size="large"
            >
                <MenuIcon htmlColor={theme.palette.secondary.main} />
            </IconButton>
            <SwipeableDrawer
                className={classes.drawer}
                anchor="left"
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                classes={{
                    paper: classes.drawerPaper
                }}
                onClick={handleClose}
            >
                <Box id="navbarDrawerHeader" boxShadow={3} className={classes.drawerHeader}>
                    <IconButton onClick={handleClose} className={classes.menuCloseButton} size="large">
                        <CloseIcon />
                    </IconButton>
                    <Link href={urls.home()}>
                        <img src={context?.logoUrl} className={navbarClasses.logo} />
                    </Link>
                </Box>
                <Divider />
                <ScrollBar>
                    <List>
                        <Box px={1}>
                            {isLoggedIn && (
                                <Box mb={10}>
                                    {!userIsOnlyInTraineeGroup && (
                                        <Item
                                            text={gettext('Create content')}
                                            href={urls.myContent()}
                                            className={classes.bold}
                                        />
                                    )}
                                    <Box my={1} />
                                    <Item
                                        text={currentPortal() === 'drg' ? gettext('my conrad') : gettext('my content')}
                                        className={classes.bold}
                                        href={urls.home()}
                                    />
                                    <Item text={gettext('last seen')} href={`${urls.home()}lastseen/`} />
                                    <Item text={gettext('my activated content')} href={`${urls.home()}purchased/`} />
                                    <Item text={gettext('my bookmarked content')} href={`${urls.home()}bookmarked/`} />
                                    <Item text={gettext('my lists')} href={`${urls.home()}lists/`} />
                                </Box>
                            )}
                            <Box mb={10}>
                                <CollapsibleItem text={pgettext('Rubriken', 'Categories')} className={classes.bold}>
                                    {carouselConfigs &&
                                        carouselConfigs.map(config => (
                                            <Item
                                                key={`${config.title}_${config.id}`}
                                                text={config.title}
                                                href={`${urls.home()}single_carousel/${config.id}/`}
                                            />
                                        ))}
                                </CollapsibleItem>
                            </Box>
                            <Box>
                                <Divider />
                                {overviewPagesContains('aboutUs') && (
                                    <Item text={gettext('About us')} href={urls.overview('aboutUs')} />
                                )}
                                {overviewPagesContains('contribute') && (
                                    <Item text={gettext('Contribute')} href={urls.overview('contribute')} />
                                )}
                                {isLoggedIn && (
                                    <Item
                                        text={gettext('Help pages')}
                                        href={urls['mevis_help:help']()}
                                        target="_blank"
                                    />
                                )}
                                {isLoggedIn && (
                                    <Item text={gettext('Support')} href={urls['mevis_support_form:support']()} />
                                )}
                                {overviewPagesContains('contact') && (
                                    <Item text={gettext('Contact')} href={urls.overview('contact')} />
                                )}
                                <Item text={gettext('Redeem CODE')} href={urls['promocodes:promoCode']()} />

                                {!isLoggedIn && registrationOpen && (
                                    <Item
                                        text={gettext('Set up trial access')}
                                        href={urls.registrationRegisterGuest()}
                                    />
                                )}
                                {currentPortal() === 'drg' && (
                                    <ListItem component="a" onClick={switchDarkLightMode} disabled={disableTheming}>
                                        <ListItemText
                                            primary={gettext('Dark-/Light-Mode')}
                                            title={
                                                disableTheming
                                                    ? gettext('Light mode is not yet supported for this page.')
                                                    : undefined
                                            }
                                        />
                                    </ListItem>
                                )}
                                <Divider />
                            </Box>
                        </Box>
                    </List>
                </ScrollBar>
            </SwipeableDrawer>
        </React.Fragment>
    )
}
