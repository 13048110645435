/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import {
    CollectionsBookmark as BookmarksIcon,
    InvertColors as InvertColorsIcon,
    LibraryAdd as CreateContentIcon,
    LibraryBooks as ListIcon,
    PersonOutline as PersonOutlineIcon,
    VideoLibrary as ActivatedContentIcon
} from '@mui/icons-material'
import {
    AppBar,
    Box,
    Divider,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useSelector } from 'react-redux'

import { currentPortal } from 'components/materialTheme/commonTheme'
import { switchDarkLightMode } from 'components/materialTheme/theme'
import NavbarSearch from 'components/search/NavbarSearch'
import type { StateType } from 'components/search/redux'
import useUrls from 'hooks/useUrls'

import AccountCircle from './AccountCircle'
import { LogoutIcon, LoginIcon } from './LogoutLoginIcon'
import useNavbarStyles from './navbarStyles'
import SideMenu from './SideMenu'

const appBarStyles = makeStyles(theme => ({
    root: {
        height: '72px',
        backgroundColor: theme.palette.navbar.backgroundColor,
        [theme.breakpoints.down('sm')]: {
            height: '62px'
        }
    }
}))

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    username: {
        fontWeight: 700,
        marginRight: 5,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    menuText: {
        marginLeft: 5
    },
    searchWrapper: {
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end'
        }
    },
    menuItem: {
        padding: 0
    },
    menuLink: {
        width: '100%',
        padding: '6px 16px'
    }
}))

const disableTheming = Boolean(document.querySelector('.doNotTheme'))

export default function Navbar({ username, logoUrl }: { username: string; logoUrl: string }) {
    const classes = useStyles()
    const appBarClasses = appBarStyles()
    const navbarClasses = useNavbarStyles()
    const theme = useTheme()
    const urls = useUrls()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [fullWidthSearch, setFullWidthSearch] = React.useState(false)
    const isLoggedIn = useSelector((state: StateType) => state.isLoggedIn)
    const showOnlySearch = fullWidthSearch && isSmallScreen

    const open = Boolean(anchorEl)

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const switchTheme = () => {
        switchDarkLightMode()
    }

    function getMenuItem(url, Icon, text, target = '_self') {
        return (
            <MenuItem className={classes.menuItem} onClick={handleClose}>
                <Link href={url} className={classes.menuLink} target={target}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Icon htmlColor={theme.palette.secondary.main} />
                        <Typography className={classes.menuText}>{text}</Typography>
                    </Box>
                </Link>
            </MenuItem>
        )
    }

    function getMenuItems(isLoggedIn) {
        return (
            <React.Fragment>
                {currentPortal() === 'drg' && (
                    <span
                        title={
                            disableTheming
                                ? gettext('Light mode is not yet supported for this page.')
                                : gettext('Dark-/Light-Mode')
                        }
                    >
                        <IconButton onClick={switchTheme} color="inherit" disabled={disableTheming} size="large">
                            <InvertColorsIcon
                                htmlColor={theme.palette.secondary.main}
                                opacity={disableTheming ? 0.3 : 1.0}
                            />
                        </IconButton>
                    </span>
                )}
                <IconButton
                    onClick={handleMenu}
                    color="inherit"
                    style={{ borderRadius: '4px' }}
                    data-testid="navbarMenu"
                    size="large"
                >
                    <Typography variant="body2" className={classes.username}>
                        {username}
                    </Typography>
                    <AccountCircle htmlColor={theme.palette.secondary.main} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    {!isLoggedIn && <div>{getMenuItem(urls.login(), LoginIcon, gettext('Sign In'))}</div>}
                    {isLoggedIn && (
                        <Box>
                            {getMenuItem(
                                `${urls.home()}purchased/`,
                                ActivatedContentIcon,
                                gettext('my activated content')
                            )}
                            {getMenuItem(`${urls.home()}bookmarked/`, BookmarksIcon, gettext('my bookmarked content'))}
                            {getMenuItem(`${urls.home()}lists/`, ListIcon, gettext('my lists'))}
                            {getMenuItem(urls.myContent(), CreateContentIcon, gettext('Create content'))}
                            {getMenuItem(
                                urls['promocodes:promoCode'](),
                                PersonOutlineIcon,
                                gettext('Redeem Promo Code'),
                                '_blank'
                            )}
                            <Divider />
                            {getMenuItem(urls.authLogout(), LogoutIcon, gettext('Sign Out'))}
                        </Box>
                    )}
                </Menu>
            </React.Fragment>
        )
    }

    const SearchComponent = () => (
        <NavbarSearch
            fullWidthSearch={fullWidthSearch}
            isSmallScreen={isSmallScreen}
            setFullWidthSearch={setFullWidthSearch}
        />
    )

    return (
        <AppBar position="fixed" classes={appBarClasses}>
            <Toolbar style={{ minHeight: '62px' }}>
                {showOnlySearch ? (
                    <SearchComponent />
                ) : (
                    <React.Fragment>
                        <SideMenu />
                        <Link href={urls.home()}>
                            <img src={logoUrl} className={navbarClasses.logo} />
                        </Link>
                        <Box display="flex" flexGrow="1" alignItems="center" className={classes.searchWrapper}>
                            <SearchComponent />
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="flex-end">
                            {getMenuItems(isLoggedIn)}
                        </Box>
                    </React.Fragment>
                )}
            </Toolbar>
        </AppBar>
    )
}
