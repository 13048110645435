/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { configureStore } from '@reduxjs/toolkit'
import axios from 'axios'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import MaterialRoot from 'components/materialTheme/MaterialRoot'
import reducer from 'components/search/redux'

import Navbar from './Navbar'

const navbarRoot = document.getElementById('navbarRoot')

async function main() {
    const isInFrame = window.parent !== window
    if (isInFrame) {
        if (navbarRoot) {
            navbarRoot.style.display = 'none'
        }
        return
    }

    const { isAuthenticated, username, userIsOnlyInTraineeGroup, logoUrl, registrationOpen } = (
        await axios.get(window.Urls.navbarContext())
    ).data

    const store = configureStore({
        reducer,
        preloadedState: {
            isLoggedIn: isAuthenticated,
            userIsOnlyInTraineeGroup,
            suggestions: [],
            value: new URLSearchParams(window.location.search).get('search') || '',
            registrationOpen
        },
        devTools: { name: 'NavbarSearch' }
    })
    const root = createRoot(navbarRoot)
    root.render(
        <Provider store={store}>
            <MaterialRoot seed="navbar">
                <Navbar username={username} logoUrl={logoUrl} />
            </MaterialRoot>
        </Provider>
    )
}

main()
